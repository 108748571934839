import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalstorageService implements Storage {
	private isPlatformBrowser: boolean;

	constructor(@Inject(PLATFORM_ID) private platformId: string) {
		this.isPlatformBrowser = isPlatformBrowser(this.platformId);
	}

	[name: string]: unknown;
	length!: number;

	clear(): void {
		this.isPlatformBrowser && localStorage.clear();
	}

	getItem(key: string): string | null {
		return this.isPlatformBrowser ? localStorage.getItem(key) : null;
	}

	key(index: number): string | null {
		return this.isPlatformBrowser ? localStorage.key(index) : null;
	}

	removeItem(key: string): void {
		this.isPlatformBrowser && localStorage.removeItem(key);
	}

	setItem(key: string, value: string): void {
		this.isPlatformBrowser && localStorage.setItem(key, value);
	}
}
