import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { UserRolesEnum, YunoUserRolesEnum, isXKPUser } from '@yuno/api/interface';

import { authActions } from './auth.actions';
import { AuthState, AuthStatus, StateValidators } from './auth.models';

export const initialState: AuthState = {
	isAuthenticated: false,
	hasAuthenticationError: false,
	hasAppRole: false,

	user: undefined,
	appState: undefined,
	appStateStatus: StateValidators.PENDING,

	accessTokenStatus: AuthStatus.PENDING,
	authenticationStatus: StateValidators.PENDING,

	requestResetError: undefined,
	requestResetStatus: StateValidators.PENDING,

	setPasswordStatus: StateValidators.PENDING,
	setPasswordTokenStatus: StateValidators.PENDING,

	createUserStatus: StateValidators.PENDING
};

const reducer = createReducer(
	initialState,
	// ON LOGIN
	on(
		authActions.login,
		(state): AuthState => ({
			...state,
			authenticationStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.loginPending,
		(state): AuthState => ({
			...state,
			authenticationStatus: StateValidators.PENDING
		})
	),
	on(
		authActions.loginSuccess,
		(state, action): AuthState => ({
			...state,
			user: action.user,
			authenticationStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.loginFailure,
		(): AuthState => ({
			...initialState,
			isAuthenticated: false,
			hasAuthenticationError: true,
			authenticationStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.loginComplete,
		(state): AuthState => ({
			...state,
			isAuthenticated: true,
			hasAuthenticationError: false
		})
	),
	on(
		authActions.loginRedirect,
		(state): AuthState => ({
			...state
		})
	),

	// LOGOUT
	on(
		authActions.logout,
		(state): AuthState => ({
			...initialState,
			hasAuthenticationError: state.hasAuthenticationError
		})
	),
	// _setPasswordTokenReducer
	on(
		authActions.setPasswordToken,
		(state): AuthState => ({
			...state,
			setPasswordTokenStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.setPasswordTokenPending,
		(state): AuthState => ({
			...state,
			setPasswordTokenStatus: StateValidators.PENDING
		})
	),
	on(
		authActions.setPasswordTokenSuccess,
		(state): AuthState => ({
			...state,
			setPasswordTokenStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.setPasswordTokenFailure,
		(): AuthState => ({
			...initialState,
			setPasswordTokenStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.setPasswordTokenComplete,
		(state): AuthState => ({
			...state,
			setPasswordTokenStatus: StateValidators.PENDING
		})
	),
	// _setPasswordReducer
	on(
		authActions.setPassword,
		(state): AuthState => ({
			...state,
			setPasswordStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.setPasswordPending,
		(state): AuthState => ({
			...state,
			setPasswordStatus: StateValidators.PENDING
		})
	),
	on(
		authActions.setPasswordSuccess,
		(state): AuthState => ({
			...state,
			setPasswordStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.setPasswordFailure,
		(): AuthState => ({
			...initialState,
			isAuthenticated: false,
			hasAuthenticationError: true,
			setPasswordStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.setPasswordComplete,
		(state): AuthState => ({
			...state
		})
	),
	// _resetPasswordReducer
	on(
		authActions.requestPassword,
		(state): AuthState => ({
			...state,
			requestResetStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.requestPasswordSuccess,
		(state): AuthState => ({
			...state,
			requestResetStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.requestPasswordFailed,
		(state, action): AuthState => ({
			...state,
			requestResetError: action.error.status,
			requestResetStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.requestPasswordReset,
		(state): AuthState => ({
			...state,
			requestResetError: undefined,
			requestResetStatus: StateValidators.PENDING
		})
	),
	// _createUserReducer
	on(
		authActions.createUser,
		(state): AuthState => ({
			...state,
			createUserStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.createUserPending,
		(state): AuthState => ({
			...state,
			createUserStatus: StateValidators.PENDING
		})
	),
	on(
		authActions.createUserSuccess,
		(state): AuthState => ({
			...state,
			createUserStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.createUserFailure,
		(): AuthState => ({
			...initialState,
			isAuthenticated: false,
			hasAuthenticationError: true,
			createUserStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.setPasswordComplete,
		(state): AuthState => ({
			...state
		})
	),
	// ACCESS TOKEN
	on(
		authActions.accessTokenRefresh,
		(state): AuthState => ({
			...state,
			accessTokenStatus: AuthStatus.PENDING
		})
	),
	on(
		authActions.accessTokenSuccess,
		(state): AuthState => ({
			...state,
			accessTokenStatus: AuthStatus.VALID
		})
	),
	on(
		authActions.accessTokenFailure,
		(state): AuthState => ({
			...state,
			accessTokenStatus: AuthStatus.INVALID,
			authenticationStatus: StateValidators.FAILED
		})
	),

	// APP
	on(
		authActions.appRoleVerify,
		(state): AuthState => ({
			...state,
			appStateStatus: StateValidators.VALIDATING
		})
	),
	on(
		authActions.appRolePending,
		(state): AuthState => ({
			...state,
			appStateStatus: StateValidators.PENDING
		})
	),
	on(
		authActions.appRoleSuccess,
		(state, action): AuthState => ({
			...state,
			appState: action.appState,
			appStateStatus: StateValidators.SUCCESS
		})
	),
	on(
		authActions.appRoleFailure,
		(): AuthState => ({
			...initialState,
			hasAppRole: false,
			appStateStatus: StateValidators.FAILED
		})
	),
	on(
		authActions.appRoleComplete,
		(state): AuthState => ({
			...state,
			hasAppRole: true
		})
	)
);

export const authFeature = createFeature({
	name: 'auth',
	reducer,
	extraSelectors: ({ selectAuthState }) => ({
		// Lookup the 'Auth' feature state managed by NgRx

		//
		selectRequestError: createSelector(selectAuthState, state => state.requestResetError),
		selectRequestReset: createSelector(selectAuthState, state => state.requestResetStatus),

		// Authentication Data
		authenticationStatus: createSelector(selectAuthState, state => state.authenticationStatus),
		selectIsAuthenticated: createSelector(selectAuthState, state => state.isAuthenticated),
		selectAuthenticationError: createSelector(
			selectAuthState,
			state => state.hasAuthenticationError
		),

		// SetPassword
		setPasswordTokenStatus: createSelector(
			selectAuthState,
			state => state.setPasswordTokenStatus
		),

		setPasswordStatus: createSelector(selectAuthState, state => state.setPasswordStatus),
		setPasswordError: createSelector(selectAuthState, state => state.setPasswordStatus),

		// Get user data
		selectUser: createSelector(selectAuthState, state => state.user),
		XKPuser: createSelector(selectAuthState, state => {
			// No role available
			if (!state.user?.role) {
				return false;
			}
			return isXKPUser(state.user.role);
		}),
		userRole: createSelector(
			selectAuthState,
			state => state.user?.role || UserRolesEnum.NONUSER
		),

		// Get AppRole
		appRole: createSelector(
			selectAuthState,
			state => state.appState?.appRole || YunoUserRolesEnum.ATLAS
		),
		appRoleState: createSelector(selectAuthState, state => {
			return {
				appState: state.appState,
				appStateStatus: state.appStateStatus
			};
		}),
		appState: createSelector(selectAuthState, state => state.appState)
	})
});
