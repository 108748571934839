import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppUserRole } from '@yuno/api/interface';

import { AUTH_URL } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class AppAuthService {
	private http = inject(HttpClient);
	authUrl = inject(AUTH_URL);

	verifyAppRole(appId: string): Observable<AppUserRole> {
		return this.http.get<AppUserRole>(`${this.authUrl}/app/user/${appId}/role`);
	}
}
