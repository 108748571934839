/**
 * Lazy load services in Angular
 * https://netbasal.com/lazy-load-services-in-angular-bcf8eae406c8
 */
import { Injectable, Injector, ProviderToken } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LazyInject {
	constructor(private injector: Injector) {}

	async get<T>(providerLoader: () => Promise<ProviderToken<T>>) {
		return this.injector.get(await providerLoader());
	}
}
