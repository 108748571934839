import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppState, AuthUser } from './auth.models';

export const authActions = createActionGroup({
	source: 'auth',
	events: {
		login: props<{ email: string; password: string }>(),
		loginPending: emptyProps(),
		loginSuccess: props<{ user: AuthUser }>(),
		loginComplete: emptyProps(),
		loginRedirect: emptyProps(),
		loginFailure: props<{ error: Error }>(),

		// App
		appRoleVerify: props<{ appId: string }>(),
		appRolePending: emptyProps(),
		appRoleSuccess: props<{ appState: AppState }>(),
		appRoleComplete: emptyProps(),
		appRoleFailure: props<{ error: Error }>(),

		// Logout
		logout: emptyProps(),

		// Refresh Tokens
		accessTokenRefresh: emptyProps(),
		accessTokenSuccess: emptyProps(),
		accessTokenFailure: emptyProps(),

		// Validate SetPassword TOKEN
		setPasswordToken: props<{ token: string }>(),
		setPasswordTokenPending: emptyProps(),
		setPasswordTokenSuccess: emptyProps(),
		setPasswordTokenComplete: emptyProps(),
		setPasswordTokenFailure: props<{ error: HttpErrorResponse | string }>(),
		// Set Password
		setPassword: props<{ resetToken: string; password: string }>(),
		setPasswordPending: emptyProps(),
		setPasswordSuccess: emptyProps(),
		setPasswordComplete: emptyProps(),
		setPasswordFailure: props<{ error: HttpErrorResponse | string }>(),
		// Reset password
		requestPassword: props<{ email: string }>(),
		requestPasswordReset: emptyProps(),
		requestPasswordSuccess: emptyProps(),
		requestPasswordFailed: props<{ error: HttpErrorResponse }>(),
		// Create User
		createUser: props<{
			resetToken: string;
			displayName: string;
			language: string;
			password: string;
		}>(),
		createUserPending: emptyProps(),
		createUserSuccess: emptyProps(),
		createUserComplete: emptyProps(),
		createUserFailure: props<{ error: HttpErrorResponse | string }>()
	}
});
