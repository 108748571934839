import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { LocalstorageService } from '@yuno/angular/services';

import { TOKEN_KEY } from '../+state/auth.models';

@Injectable({
	providedIn: 'root'
})
export class AccessTokenStorageService {
	private accessTokenKey = TOKEN_KEY;
	private cookie = inject(CookieService);
	private localStorage = inject(LocalstorageService);

	getAccessToken(): string {
		// Check if we have a Google Login token
		const token = this.cookie.get(this.accessTokenKey);

		// Else the user logged in using the default login method
		if (!token) {
			return this.localStorage.getItem(this.accessTokenKey) as string;
		}

		// When we have a Google Token, we need to save it to the local storage aswell
		this.saveAccessToken(token);

		// then remove the cookie
		this.cookie.delete(this.accessTokenKey);

		return token;
	}

	saveAccessToken(token: string) {
		this.localStorage.setItem(this.accessTokenKey, token);
	}

	removeTokens() {
		this.localStorage.removeItem(this.accessTokenKey);
	}
}
