import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, of, switchMap } from 'rxjs';

import { LocalstorageService } from '@yuno/angular/services';
import {
	AppUserRole,
	UserRoles,
	YunoUserRoles,
	maximumAppRole,
	minimalUserRole,
	minimalYunoRole
} from '@yuno/api/interface';

import { authActions } from './auth.actions';
import { AuthUser, TOKEN_KEY } from './auth.models';
import { authFeature } from './auth.state';

@Injectable({
	providedIn: 'root'
})
export class AuthFacade {
	private readonly store = inject(Store);
	private localStorage = inject(LocalstorageService);

	user$ = this.store.pipe(select(authFeature.selectUser));
	XKPuser$ = this.store.pipe(select(authFeature.XKPuser));
	userRole$ = this.store.pipe(select(authFeature.userRole));
	resetRequest$ = this.store.pipe(select(authFeature.selectRequestReset));
	resetRequestError$ = this.store.pipe(select(authFeature.selectRequestError));

	setPasswordStatus$ = this.store.pipe(select(authFeature.setPasswordStatus));
	setPasswordTokenStatus$ = this.store.pipe(select(authFeature.setPasswordTokenStatus));

	createUserStatus$ = this.store.pipe(select(authFeature.selectCreateUserStatus));
	appRole$ = this.store.select(authFeature.appRole);
	appState$ = this.store.select(authFeature.appState);
	isLoggedIn$ = this.store.select(authFeature.selectIsAuthenticated);
	isAuthenticated$ = this.store.select(authFeature.selectIsAuthenticated);
	hasLoginError$ = this.store.select(authFeature.selectAuthenticationError);
	authenticationStatus$ = this.store.select(authFeature.authenticationStatus);

	getJWTToken(): string | null {
		return this.localStorage.getItem(TOKEN_KEY);
	}

	//
	// LOGIN FUNCTIONS
	//
	login(email: string, password: string): void {
		this.store.dispatch(authActions.login({ email, password }));
	}

	loginPending(): void {
		this.store.dispatch(authActions.loginPending());
	}

	loginFailed(error: Error): void {
		this.store.dispatch(authActions.loginFailure({ error }));
	}

	loginSuccess(user: AuthUser): void {
		this.store.dispatch(authActions.loginSuccess({ user }));
	}

	loginRedirect(): void {
		this.store.dispatch(authActions.loginRedirect());
	}

	logout(): void {
		this.store.dispatch(authActions.logout());
	}

	//
	// END LOGIN FUNCTIONS
	//

	//
	// SetPasswordTokens
	//
	setPasswordToken(token: string): void {
		this.store.dispatch(authActions.setPasswordToken({ token }));
	}

	setPasswordTokenFailed(error: string): void {
		this.store.dispatch(authActions.setPasswordTokenFailure({ error }));
	}

	setPasswordTokenSuccess(): void {
		this.store.dispatch(authActions.setPasswordTokenSuccess());
	}

	//
	// END SetPasswordTokens
	//

	//
	// Set Password
	//
	setPassword(resetToken: string, password: string): void {
		this.store.dispatch(authActions.setPassword({ resetToken, password }));
	}

	setPasswordPending(): void {
		this.store.dispatch(authActions.setPasswordPending());
	}

	setPasswordFailed(error: string): void {
		this.store.dispatch(authActions.setPasswordFailure({ error }));
	}

	setPasswordComplete(): void {
		this.store.dispatch(authActions.setPasswordComplete());
	}

	//
	// END Set Password
	//

	//
	// Set Create User
	//
	createUser(resetToken: string, displayName: string, language: string, password: string): void {
		this.store.dispatch(
			authActions.createUser({ resetToken, displayName, language, password })
		);
	}

	createUserPending(): void {
		this.store.dispatch(authActions.createUserPending());
	}

	createUserFailed(error: string): void {
		this.store.dispatch(authActions.createUserFailure({ error }));
	}

	createUserComplete(): void {
		this.store.dispatch(authActions.createUserComplete());
	}

	//
	// END Create User
	//

	//
	// ACCESS TOKEN
	//
	accessTokenSuccess(): void {
		this.store.dispatch(authActions.accessTokenSuccess());
	}

	requestPassword(email: string): void {
		this.store.dispatch(authActions.requestPassword({ email }));
	}

	requestPasswordReset(): void {
		this.store.dispatch(authActions.requestPasswordReset());
	}

	//
	// END ACCESS TOKEN
	//

	appRoleVerify(appId: string): void {
		this.store.dispatch(authActions.appRoleVerify({ appId }));
	}

	appRolePending(): void {
		this.store.dispatch(authActions.appRolePending());
	}

	appRoleFailed(error: Error): void {
		this.store.dispatch(authActions.appRoleFailure({ error }));
	}

	appRoleSuccess(appUserRole: AppUserRole): void {
		this.store.dispatch(
			authActions.appRoleSuccess({
				appState: { appId: appUserRole.appId, appRole: appUserRole.role }
			})
		);
	}

	/**
	 * Checks if a user's current role is at least equal to a minimal required role,
	 * as an Observable stream.
	 *
	 * @param {YunoUserRoles} appUserRole - The minimal required role.
	 * @returns {Observable<boolean>} An Observable stream that emits `true` if the user's current
	 * role is at least equal to the minimal required role, `false` otherwise.
	 */
	userHasMinimalAppRole$(appUserRole: YunoUserRoles): Observable<boolean> {
		return this.appRole$.pipe(
			switchMap(role => {
				if (!role) {
					return of(false);
				}

				return of(minimalYunoRole(appUserRole).includes(role));
			})
		);
	}

	/**
	 * Checks if a user's current role is at least equal to a minimal required role.
	 *
	 * @param {YunoUserRoles} minimal - The minimal required role.
	 * @param {YunoUserRoles=} currentRole - The user's current role. Optional, defaults to `undefined`.
	 * @returns {boolean} `true` if the user's current role is at least equal to the minimal required role, `false` otherwise.
	 */
	userHasMinimalAppRole(minimal: YunoUserRoles, currentRole?: YunoUserRoles): boolean {
		if (!currentRole) {
			return false;
		}

		return minimalYunoRole(minimal).includes(currentRole.toLowerCase() as YunoUserRoles);
	}

	/**
	 * Checks if a user's current role is at least equal to a minimal required role.
	 *
	 * @param {YunoUserRoles} minimal - The minimal required role.
	 * @param {YunoUserRoles=} currentRole - The user's current role. Optional, defaults to `undefined`.
	 * @returns {boolean} `true` if the user's current role is at least equal to the minimal required role, `false` otherwise.
	 */
	userHasMaximumAppRole(minimal: YunoUserRoles, currentRole?: YunoUserRoles): boolean {
		if (!currentRole) {
			return false;
		}

		return maximumAppRole(minimal).includes(currentRole.toLowerCase() as YunoUserRoles);
	}

	// END USER APP ROLE
	//

	/**
	 * Checks if a user's current role is at least equal to a minimal required role.
	 *
	 * @param {UserRoles} minimal - The minimal required role.
	 * @param {UserRoles} currentRole - The user's current role. Optional, defaults to `undefined`.
	 * @returns {boolean} `true` if the user's current role is at least equal to the minimal required role, `false` otherwise.
	 */
	userHasMinimalRole(minimal: UserRoles, currentRole?: UserRoles): boolean {
		if (!currentRole) {
			return false;
		}

		return minimalUserRole(minimal).includes(currentRole);
	}
}
