import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, Injectable, InjectionToken, OnDestroy, PLATFORM_ID, Provider, inject, isDevMode } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, fromEvent, of } from "rxjs";
import { tap } from "rxjs/operators";



import { authActions } from "../+state/auth.actions";
import { AuthTokens, AuthUser, SetPassword, UserLogin } from "../+state/auth.models";
import { AccessTokenStorageService } from "./access-token-storage.service";
import { JwtHelperService } from "./jwt-helper.service";


// export const RefreshTokenTimer = 60000;
export const RefreshTokenTimer = 10000;

export const REDIRECT = new InjectionToken<string[]>('redirect');
export const AUTH_URL = new InjectionToken<string>('authUrl');

@Injectable({
	providedIn: 'root'
})
export class AuthService implements OnDestroy {
	private readonly platformId = inject(PLATFORM_ID);
	private readonly store = inject(Store);
	private readonly router = inject(Router);

	private authUrl = inject(AUTH_URL);
	private http = inject(HttpClient);
	private jwt = inject(JwtHelperService);
	private accessTokenStorage = inject(AccessTokenStorageService);

	private refreshInterval!: number;
	route: ActivatedRoute;
	authResolveParams: string[];

	constructor() {
		if (isPlatformBrowser(this.platformId)) {
			const message$ = fromEvent<MessageEvent>(window, 'message');
			message$.subscribe((event: MessageEvent) => {
				if (
					event.origin.includes('projectatlas.app') ||
					event.origin.includes('projectatlas.tech') ||
					event.origin.includes('projectatlas.dev') ||
					(isDevMode() && event.origin.includes('localhost'))
				) {
					if (event.data.type == 'event') {
						if (event.data?.payload?.type == 'userAuth') {
							this.accessTokenStorage.saveAccessToken(
								event.data.payload.options.token
							);
							this.init();
						}
					}
				}
			});
		}
	}

	/**
	 *	Before APP launches check if a user is logged in
	 *  When logged in check if token is expired
	 *  else refresh token and store userData
	 */
	init(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			if (isPlatformBrowser(this.platformId)) {
				// do not load user data when we are hitting auth/logout
				if (window.location.href.includes('auth/logout')) {
					return resolve(true);
				}

				const token = this.accessTokenStorage.getAccessToken();
				if (!token) {
					return resolve(true);
				}

				if (!this.jwt.isJWT(token)) {
					// LOGOUT
					// the logout can cause problems with opening
					// applications. Therefore we have commented this out.
					// Lets see if we can create a fix for this
					// this.authFacade.logout();
					return resolve(true);
				}

				return resolve(this.validateUser());
			}

			// resolve true when server side rendering
			resolve(true);
		});
	}

	// validates the user data
	validateUser(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.getUser().subscribe({
				next: user => {
					this.store.dispatch(authActions.loginSuccess({ user }));
				},
				error: () => {
					// need to resolve true, when no user data is provided. To let users access Public apps
					resolve(true);
				},
				complete: () => resolve(true)
			});
		});
	}

	// Validates current User
	getUser(): Observable<AuthUser> {
		return this.http.get<AuthUser>(`${this.authUrl}/auth/user/profile`);
	}

	// LogIn a User with:
	login(email: string, password: string): Observable<UserLogin> {
		return this.http.post<UserLogin>(`${this.authUrl}/auth/login`, { email, password });
	}

	// Redirect the User to a login page
	redirectToLogin(): void {
		let urlArray: string[] = [];

		// Adds all Params
		if (this.route?.snapshot && Object.keys(this.route.snapshot.params)) {
			urlArray = [...Object.values(this.route.snapshot.params)];
		}

		// Add Intern to Url
		if (
			this.route?.snapshot &&
			this.route.snapshot.url.some(urlSegment => urlSegment.path === 'intern')
		) {
			urlArray.push('intern');
		}

		// Merge all with auth / login routes
		urlArray = [...urlArray, 'auth', 'login'];

		// Navigate to the Login Page
		this.router.navigate(urlArray, { relativeTo: this.route, queryParamsHandling: 'preserve' });
	}

	// Refresh the AccessToken
	refreshAuth(): Observable<AuthTokens> {
		return this.http.get<UserLogin>(`${this.authUrl}/auth/refresh`).pipe(
			tap(auth => {
				this.accessTokenStorage.saveAccessToken(auth.accessToken);
			})
		);
	}

	// send password reset request
	requestPassword(email: string): Observable<SetPassword | undefined> {
		if (isPlatformBrowser(this.platformId)) {
			// Get the current URL
			const currentUrl = new URL(window.location.href);
			const path = currentUrl.pathname.split('/');
			let origin = currentUrl.origin;
			if (path[1] !== 'auth') {
				origin += `/${path[1]}`;
			}

			return this.http.post<SetPassword>(`${this.authUrl}/auth/user/reset/request`, {
				email,
				origin
			});
		}

		return of(undefined);
	}

	// removes the auto refresh of authentication
	removeAuthRefresh(): void {
		this.refreshInterval && clearInterval(this.refreshInterval);
	}

	// send validation request to the server
	logout(): Observable<{ success: boolean }> {
		return this.http.get<{ success: boolean }>(`${this.authUrl}/auth/logout`);
	}

	// send validation request to the server
	validateResetToken(token: string): Observable<{ success: boolean }> {
		return this.http.get<{ success: boolean }>(
			`${this.authUrl}/auth/verify-reset-token/` + token
		);
	}

	// send password creation to the server
	setPassword(resetToken: string, password: string): Observable<SetPassword> {
		return this.http.post<SetPassword>(`${this.authUrl}/auth/user/reset/set`, {
			resetToken,
			password
		});
	}

	createUser(
		resetToken: string,
		displayName: string,
		language: string,
		password: string
	): Observable<SetPassword> {
		return this.http.post<SetPassword>(`${this.authUrl}/auth/user/create-user`, {
			resetToken,
			displayName,
			language,
			password
		});
	}

	// removes authenticated session
	clearSession(): void {
		this.removeAuthRefresh();
		this.accessTokenStorage.removeTokens();
	}

	ngOnDestroy(): void {
		this.removeAuthRefresh();
	}
}

// ON APP INITIALISATION CHECK IF A USER IS LOGGED IN
// BEFORE ANYTHING ELSE HAPPENS
export const AuthInitProvider: Provider = {
	provide: APP_INITIALIZER,
	useFactory: (authService: AuthService) => () => authService.init(),
	deps: [AuthService],
	multi: true
};
