import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalstorageService } from './local-storage.service';

@NgModule({
	imports: [CommonModule],
	providers: [LocalstorageService]
})
export class AngularServicesModule {}
