import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as AuthEffects from './auth.effects';
import { AuthFacade } from './auth.facade';
import { authFeature } from './auth.state';

@NgModule({
	imports: [],
	providers: [provideState(authFeature), provideEffects([AuthEffects]), AuthFacade]
})
export class AuthDataAccessModule {}
